<script setup>
import { ref, watchEffect } from "vue";
import { decodeCredential, googleLogout } from "vue3-google-login";
import studentsData from "../assets/students.json";

const loggedIn = ref(false);
const user = ref(null);
const ssn = ref(null);
const students = ref([]);

watchEffect(() => {
  students.value = studentsData;
});

const callback = (response) => {
  loggedIn.value = true;
  user.value = decodeCredential(response.credential);
  const studentNumber = user.value.email.split("@")[0];

  const student = students.value.find(
    (student) => student.student_number === studentNumber
  );
  ssn.value = student ? student.ssn : "No encontrado";

  console.log("Datos del usuario", studentNumber);
};

const logout = () => {
  googleLogout();
  loggedIn.value = false;
};
</script>

<template>
  <div class="main-div">
    <div v-if="loggedIn">
      <input type="checkbox" id="flipControl" />
      <label class="btn" for="flipControl">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <h3 style="text-decoration: underline">Nombre:</h3>
              <h4>{{ user.given_name }}</h4>
              <h4>{{ user.family_name }}</h4>
              <h3 style="text-decoration: underline">Código estudiante:</h3>
              <h4>{{ user.email.match(/^([^@]*)@/)[1] }}</h4>
              <h3 style="text-decoration: underline">Documento de identidad:</h3>
              <h4>{{ ssn }}</h4>
              <img
                src="https://publicaciones.columbus.edu.co/web_resources/media/logo_tcsid_2.png"
              />
            </div>

            <div class="flip-card-back">
              <div class="flex-center-card-elements">
                <div>
                  <p>
                    This card identifies you as a student of
                    <b>The Columbus School</b> and therefore the rights and duties
                    inherent in the use of the same to prove your status as a student.
                    <br />
                    <br />
                    Este carnet lo identifica como alumno del Colegio
                    <b>The Columbus School</b> y por lo tanto los derechos y deberes
                    inherentes al uso del mismo para acreditar su calidad de alumno.
                  </p>
                </div>
              </div>
              <div class="flex-center-qr">
                <div class="qr-code"></div>
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div v-else>
      <GoogleLogin :callback="callback" prompt auto-login />
    </div>
  </div>
  <footer>
    <h4>The Columbus School {{ new Date().getFullYear() }}</h4>
  </footer>
</template>

<style scoped>
@font-face {
  font-family: Open Sans Regular;
  src: url(https://www.fontsquirrel.com/fonts/open-sans);
}
.flip-card {
  width: 350px;
  height: 400px;
  perspective: 1000px;
  border-radius: 5%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5%;
}

/* .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
} */

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 5%;
  text-align: center;
}

.flip-card-front {
  background-color: #002169;
  color: black;
  border-radius: 5%;
  padding-bottom: 70px;
  padding-top: 25px;
  background-image: url(https://app.columbus.edu.co/app/storage/guides/student_id.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  border-style: solid;
  border-color: #002169;
}

.flip-card-back {
  width: 100%;
  height: 100%;
  background-color: #002169;
  color: black;
  transform: rotateY(180deg);
  border-radius: 5%;
  padding-bottom: 70px;
  padding-top: 30px;
  text-align: center;
  background-image: url(https://app.columbus.edu.co/app/storage/guides/student_id.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  border-style: solid;
  border-color: #002169;
}

.flex-center-card-elements {
  display: flex;
  justify-content: center;
}

.flex-center-qr {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

#flipControl {
  display: none;
}

#flipControl:checked + label > div .flip-card-inner {
  color: black;
  transform: rotateY(180deg);
}

img {
  border-radius: 10%;
  border: solid;
  border-color: #ffa700;
  border-width: 5px;
  width: 150px;
  height: 150px;
  object-fit: contain narrow;
  margin-top: 10px;
  padding: 5px;
}

h3 {
  color: #ffa700;
  margin: 0;
}

h4 {
  color: white;
  margin: 5px;
}

p {
  background-color: rgb(255, 255, 255);
  font-size: 70%;
  border: 7px solid #ffa600;
  border-radius: 30px;
  padding: 10px;
  width: 80%;
  height: 70%;
  text-align: center;
  margin-left: 17px;
}

.main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.qr-code {
  width: 150px;
  height: 150px;
  background-image: url(https://app.columbus.edu.co/app/storage/guides/qrtest.svg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

footer {
  position: fixed;
  height: 40px;
  background-color: #002169;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}
</style>
